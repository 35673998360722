import { Link } from "react-router-dom";
import { ClasscubeEnterprise, ClasscubePros, ClasscubeSchools } from "src/assets/svg";

const GetStarted = () => {
	return (
		<>
			<div className="max-w-[1400px] mx-auto mt-[4rem] mb-[20rem]  p-10 ">
				<h1 className="text-left text-[#0D3060] text-[36px] font-medium leading-[6.2rem] tracking-[0rem] max-w-[500px]">
					What would you like to achieve with ClassCube?
				</h1>

				<div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-10 my-[6rem]  ">
					<Link
						to="https://basic.classcube.online"
						target="_blank"
						className="text-gray border-[1.3px] border-[#c6d0e5] rounded-[8px] px-[4rem] py-[4.6rem] hover:border-primary hover:text-primary flex flex-col gap-[10px] h-[250px] w-[300px] justify-end"
					>
						<ClasscubeSchools />
						<p className="text-[1.8rem] ">ClassCube Basic</p>
					</Link>
					<Link
						to="https://pro.classcube.online"
						target="_blank"
						className="text-gray border-[1.3px] border-[#c6d0e5] rounded-[8px] px-[4rem] py-[4.6rem] hover:border-primary hover:text-primary flex flex-col gap-[10px] h-[250px] w-[300px] justify-end"
					>
						<ClasscubePros />
						<p className="text-[1.8rem]">ClassCube Pro</p>
					</Link>
					<Link
						to="https://enterprise.classcube.online"
						target="_blank"
						className="text-gray border-[1.3px] border-[#c6d0e5] rounded-[8px] px-[4rem] py-[4.6rem] hover:border-primary hover:text-primary flex flex-col gap-[10px] h-[250px] w-[300px] justify-end"
					>
						<ClasscubeEnterprise />
						<p className="text-[1.8rem]  ">ClassCube Enterprise</p>
					</Link>
				</div>
				<Link to="/" className="text-primary flex items-center md:justify-center text-center text-[1.6rem] hover:border-primary underline">
					Not sure what you want? Click here to learn more
				</Link>
			</div>
		</>
	);
};
export default GetStarted;
