import React from "react";
import { EnvelopeIcon, LocationIcon, PhoneIcon } from "src/assets/svg";
import { ErrorMessage, Formik } from "formik";
import * as yup from "yup";
import axios from "axios";
import { useMutation } from "@tanstack/react-query";
import Swal from "sweetalert2";

const Contact = () => {
	const authApi = axios.create({
		baseURL: "https://mailer.classcube.online/api/mailer",
		headers: {
			"Content-Type": "application/json",
			"Access-Control-Allow-Credentials": true,
		},
		withCredentials: true,
	});

	const sendMail = (data) => {
		return authApi.post("/send?type=classcube", data);
	};

	const { mutate, isLoading } = useMutation(sendMail);

	const validationSchema = yup.object().shape({
		email: yup.string().email("Please provide a valid email").required("Please provide a valid email"),
		firstName: yup.string().required("Please provide First Name").min(3, "First Name must be 3 characters long"),
		surname: yup.string().required("Please provide Surname").min(3, "Surname must be 3 characters long"),
		subject: yup.string().required("Please provide Subject"),
		message: yup.string().required("Please provide a message").min(20, "Message must be 20 characters long"),
	});

	const initialValues = {
		email: "",
		firstName: "",
		surname: "",
		subject: "",
		message: "",
	};

	const handleSendMail = (values, resetForm) => {
		const { firstName, surname, email, subject, message } = values;

		const requestData = {
			body: {
				firstname: firstName,
				surname: surname,
				email: "info@classcube.com",
				contactEmail: email,
				subject: subject,
				message: message,
			},
		};
		mutate(requestData, {
			onSuccess: (res) => {
				renderToastMsg(res?.data?.message, "success");
				resetForm();
			},
			onError: (err) => {
				renderToastMsg(err?.response?.data?.message);
			},
		});
	};

	return (
		<>
			<div className="max-w-[1400px] mx-auto my-[3rem] p-10">
				<h1 className="text-[4.8rem] text-secondary font-semibold leading-[6.2rem] tracking-[0rem] ">
					Get in Touch With us <br /> with Any Questions
				</h1>
				<p className="my-[1.3rem] text-gray text-[1.6rem] font-medium leading-[2.4rem] tracking-tight">
					We take our commitment to our users seriously. Have a <br />
					question or suggestion? Feel free to send us a message!
				</p>

				<div className="grid gap-[20px] lg:grid-cols-3 my-[5rem]">
					<div className="border-[1.3px] border-[#c6d0e5] rounded-[8px] p-10 ">
						<div className="flex gap-8 items-center">
							<EnvelopeIcon />
							<div>
								<p className="text-[1.8rem] text-secondary font-medium leading-[2.1rem] tracking-tight">info@classcube.com</p>
								<p className="my-[0.4rem] text-gray ">Help email support</p>
							</div>
						</div>
					</div>
					<div className="border-[1.3px] border-[#c6d0e5] rounded-[8px] p-10 ">
						<div className="flex gap-8 items-center">
							<PhoneIcon />
							<div>
								<p className="text-[1.8rem] text-secondary font-medium leading-[2.1rem] tracking-tight">+234-705-394-4594</p>
								<p className="my-[0.4rem] text-gray ">Help phone support</p>
							</div>
						</div>
					</div>
					<div className="border-[1.3px] border-[#c6d0e5] rounded-[8px] p-10 ">
						<div className="flex gap-8 items-center">
							<LocationIcon />
							<div>
								<p className="text-[1.8rem] text-secondary font-medium leading-[2.1rem] tracking-tight">
									12 Ajisafe Street, Ikeja GRA, Lagos, Nigeria
								</p>
								<p className="my-[0.4rem] text-gray ">Office Address</p>
							</div>
						</div>
					</div>
				</div>

				<div className="my-[8rem] md:w-8/12">
					<Formik
						initialValues={initialValues}
						validationSchema={validationSchema}
						onSubmit={(values, { resetForm }) => handleSendMail(values, resetForm)}
					>
						{({ values, handleChange, handleBlur, handleSubmit }) => (
							<form onSubmit={handleSubmit}>
								<div className="mb-[2rem] grid gap-[20px] lg:grid-cols-2">
									<div className="mb-[2rem]">
										<input
											type="text"
											name="firstName"
											onChange={handleChange}
											value={values.firstName}
											onBlur={handleBlur}
											placeholder="First Name"
											className="bg-lightGray border-[0.5px] border-primary text-[#5E6C85] rounded-[5px] px-[2rem] py-[1.5rem] w-full text-[1.6rem]"
										/>
										<ErrorMessage name="firstName" component="div" className="text-red-500 text-[1.2rem] my-3" />
									</div>
									<div className="mb-[2rem]">
										<input
											type="text"
											name="surname"
											onChange={handleChange}
											value={values.surname}
											onBlur={handleBlur}
											placeholder="Surname"
											className="bg-lightGray border-[0.5px] border-primary text-[#5E6C85] rounded-[5px] px-[2rem] py-[1.5rem] w-full text-[1.6rem]"
										/>
										<ErrorMessage name="surname" component="div" className="text-red-500 text-[1.2rem] my-3" />
									</div>
								</div>

								<div className="mb-[2rem] grid gap-[20px] lg:grid-cols-2">
									<div className="mb-[2rem]">
										<input
											type="email"
											name="email"
											onChange={handleChange}
											value={values.email}
											onBlur={handleBlur}
											placeholder="Email "
											className="bg-lightGray text-[#5E6C85] border-[0.5px] border-primary rounded-[5px] px-[2rem] py-[1.5rem] w-full text-[1.6rem]"
										/>
										<ErrorMessage name="email" component="div" className="text-red-500 text-[1.2rem] my-3" />
									</div>

									<div className="mb-[2rem]">
										<input
											type="text"
											name="subject"
											onChange={handleChange}
											value={values.subject}
											onBlur={handleBlur}
											placeholder="Subject"
											className="bg-lightGray text-[#5E6C85] border-[0.5px] border-primary rounded-[5px] px-[2rem] py-[1.5rem] w-full text-[1.6rem]"
										/>
										<ErrorMessage name="subject" component="div" className="text-red-500 text-[1.2rem] my-3" />
									</div>
								</div>

								<div className="mb-[4rem]">
									<textarea
										placeholder="Message"
										onChange={handleChange}
										value={values.message}
										onBlur={handleBlur}
										name="message"
										className="bg-lightGray h-[15rem] text-[#5E6C85] border-[0.5px] border-primary rounded-[5px] px-[2rem] py-[1.5rem] w-full text-[1.6rem]"
									/>
									<ErrorMessage name="message" component="div" className="text-red-500 text-[1.2rem] my-3" />
								</div>

								<button
									type="submit"
									disabled={isLoading}
									className="shadow-btnShadow w-full bg-primary rounded-[5px] p-[1.3rem] btn-loader text-white text-[1.6rem] leading-[2.4rem] font-medium"
								>
									{" "}
									Submit
								</button>
							</form>
						)}
					</Formik>
				</div>
			</div>
		</>
	);
};

export const renderToastMsg = (message, icon = "error", time = 10000) => {
	const Toast = Swal.mixin({
		toast: true,
		position: "top-end",
		showConfirmButton: false,
		timer: time,
		timerProgressBar: true,
		width: "auto",
	});

	Promise.resolve(
		Toast.fire({
			icon: icon,
			title: message,
		})
	);
};

export default Contact;
