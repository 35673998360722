import React, { useEffect, useRef, useState } from "react";
import { classcubeFaqs, features } from "../assets/constant";
import { QuotesIcon } from "src/assets/svg";
import { CustomAccordion } from "src/component";
import { Banner, ClasscubeEnterprise, ClasscubePro, ClasscubeSch } from "src/assets";
import { HiArrowSmRight } from "react-icons/hi";
import { useNavigate } from "react-router-dom";

const Home = () => {
	return (
		<>
			{/* Hero section */}
			<HeroSection />

			{/* Products */}
			<Products />

			{/* features */}
			<Features />

			{/* testimony */}
			<Testimony />

			{/* FAQ */}
			<Faqs />
		</>
	);
};

export const HeroSection = () => {
	const navigate = useNavigate();
	return (
		<div className="relative">
			<div className="dot"></div>

			<div className="max-w-[1400px] mx-auto my-[6rem] flex flex-col lg:flex-row justify-start items-center p-10 ">
				<div className="mb-[5rem] md:w-[90%] lg:lg:w-[50%]">
					<h1 className="text-left text-secondary text-[4.8rem] font-semibold leading-[6.2rem] tracking-[-0.09rem]">
						Smart Digital Learning for Schools and Businesses
					</h1>
					<p className="lg:w-[80%] text-[1.8rem] text-gray my-[1.4rem] leading-[2.8rem]">
						A versatile online learning management system that offers various versions to cater to schools, educators, content creators,
						and organizations.
					</p>
					<button
						className="my-[2rem] py-[10px] px-[30px] rounded-[5px] bg-primary text-white shadow-btnShadow"
						onClick={() => {
							navigate("/getstarted");
						}}
					>
						Get Started
					</button>
				</div>

				<img src={Banner} alt="banner" className="" />
			</div>
		</div>
	);
};

export const Features = () => {
	return (
		<>
			<div className="bg-[#F7F9FB] my-[10rem] p-10">
				<div className="max-w-[1400px] mx-auto my-[5rem] flex flex-col justify-center items-center p-10 ">
					<div className="mb-[5rem] mx-auto md:w-[70%] lg:w-[40%]">
						<h3 className="text-center text-secondary text-[3.6rem] font-semibold leading-[4.8rem] tracking-[0rem] ">
							Take a look at our Simple, yet Powerful Features
						</h3>
					</div>

					<div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-[3rem]">
						{features.map((feature, index) => (
							<div key={index}>
								<div className="flex flex-col gap-[0.8rem] ">
									{feature.icon}
									<h4 className=" text-secondary text-[2rem] font-semibold leading-[2.7rem]">{feature.title}</h4>
									<p className=" text-gray text-[1.6rem] font-medium leading-[2.7rem]">{feature.content}</p>
								</div>
							</div>
						))}
					</div>
				</div>
			</div>
		</>
	);
};

export const Products = () => {
	const [activeProduct, setActiveProduct] = useState("schools");
	const [isSticky, setIsSticky] = useState(false);
	const productRef = useRef(null);

	const scrollPositions = {
		schools: 0,
		pro: 1000,
		enterprise: 1500,
	};

	const handleScroll = () => {
		const scrollY = window.scrollY;

		if (scrollY >= scrollPositions.schools && scrollY < scrollPositions.pro) {
			setActiveProduct("schools");
		} else if (scrollY >= scrollPositions.pro && scrollY < scrollPositions.enterprise) {
			setActiveProduct("pro");
		} else if (scrollY >= scrollPositions.enterprise) {
			setActiveProduct("enterprise");
		}

		const containerRect = productRef.current.getBoundingClientRect();
		setIsSticky(containerRect.top <= 0);
	};

	useEffect(() => {
		window.addEventListener("scroll", handleScroll);

		return () => {
			window.removeEventListener("scroll", handleScroll);
		};
	}, []);

	const handleContainerScroll = (e) => {
		// Prevent the default scroll behavior when the container is active
		if (isSticky) {
			e.preventDefault();
		}
	};

	return (
		<div className="h-[100rem] lg:h-[200rem] !scroll-smooth">
			<div className="sticky top-10 overflow-y-auto" style={{ overflowY: isSticky ? "auto" : "hidden" }} onScroll={handleContainerScroll}>
				<div className="max-w-[1400px] mx-auto my-[6rem] flex flex-col lg:flex-row justify-start items-start p-10">
					<div className="w-full mb-[3rem] lg:w-4/12 " ref={productRef}>
						<h3 className="text-left text-secondary text-[3.6rem] font-semibold leading-[4.8rem] tracking-[0rem] lg:w-[90%]">
							Learning Beyond Walls
						</h3>
						<p className="text-[1.6rem] font-bold text-primary my-[2.1rem] flex items-center gap-2">
							Explore our products <HiArrowSmRight />
						</p>
						<div className="flex flex-wrap gap-10 lg:flex-col">
							<p
								className={`cursor-pointer text-[1.6rem] px-4 py-2 rounded-l-lg font-medium  ${
									activeProduct === "schools" ? "border-l-4 border-l-primary" : ""
								} text-[#5E6C85]  flex items-center gap-2`}
								onClick={() => setActiveProduct("schools")}
							>
								ClassCube Basic
							</p>
							<p
								className={`cursor-pointer text-[1.6rem] px-4 py-2 rounded-l-lg font-medium ${
									activeProduct === "pro" ? "border-l-4 border-l-primary" : ""
								} text-[#5E6C85]  flex items-center gap-2`}
								onClick={() => setActiveProduct("pro")}
							>
								ClassCube Pro
							</p>
							<p
								className={`cursor-pointer text-[1.6rem] px-4 py-2 rounded-l-lg font-medium ${
									activeProduct === "enterprise" ? "border-l-4 border-l-primary" : ""
								} text-[#5E6C85]  flex items-center gap-2`}
								onClick={() => setActiveProduct("enterprise")}
							>
								ClassCube Enterprise
							</p>
						</div>
					</div>

					<div className="w-full lg:w-8/12">
						<div
							className={`bg-[#F7F9FB] rounded-[3rem] overflow-hidden h-[55rem] lg:h-[65rem] pl-[5rem] pt-[5rem] ${
								activeProduct === "schools" ? "block opacity-100" : "hidden opacity-0"
							}`}
						>
							<p className="text-primary text-[1.2rem] font-medium leading-none tracking-[1px]">CLASSCUBE BASIC</p>
							<div className="w-full lg:w-[50%] pr-[5rem] lg:pr-0">
								<p className="text-secondary text-[2rem]  my-[10px] font-medium">
									Digital Space for Creating and Managing Learning Activities
								</p>
								<p className="text-gray text-[1.4rem]  my-[10px] font-normal">
									Ideal for educational institutions looking to digitize and enhance their learning environment.
								</p>
								<button
									className="my-[1.3rem] cursor-pointer py-[10px] px-[20px] rounded-[5px] bg-primary text-[1.4rem] text-white shadow-btnShadow"
									onClick={() => {
										window.open("https://basic.classcube.online", "_blank");
									}}
								>
									{" "}
									Get Started
								</button>
							</div>
							<div className="my-[3rem]">
								<img src={ClasscubeSch} alt="ClassCube Basic" width={1000} />
							</div>
						</div>

						<div
							className={`bg-[#F7F9FB] rounded-[3rem] overflow-hidden h-[55rem] lg:h-[65rem] pl-[5rem] pt-[5rem] ${
								activeProduct === "pro" ? "block opacity-100" : "hidden opacity-0"
							}`}
						>
							<p className="text-primary text-[1.2rem] font-medium leading-none tracking-[1px]">CLASSCUBE PRO</p>
							<div className="w-full lg:w-[50%] lg:pr-0">
								<p className="text-secondary text-[2rem]  my-[10px] font-medium">All the Courses You Need in one Place</p>
								<p className="text-gray text-[1.4rem]  my-[10px] font-normal">
									Perfect for educators and content creators who want to offer courses and earn revenue.
								</p>
								<button
									className="my-[1.3rem] cursor-pointer py-[10px] px-[20px] rounded-[5px] bg-primary text-[1.4rem] text-white shadow-btnShadow"
									onClick={() => {
										window.open("https://pro.classcube.online", "_blank");
									}}
								>
									Get Started
								</button>
							</div>
							<div className="my-[3rem]">
								<img src={ClasscubePro} alt="ClassCube Basic" width={1000} />
							</div>
						</div>
						<div
							className={`bg-[#F7F9FB] rounded-[3rem] overflow-hidden h-[55rem] lg:h-[65rem] pl-[5rem] pt-[5rem] ${
								activeProduct === "enterprise" ? "block opacity-100" : "hidden opacity-0"
							}`}
						>
							<p className="text-primary text-[1.2rem] font-medium leading-none tracking-[1px]">CLASSCUBE ENTERPRISE</p>
							<div className=" w-full lg:w-[60%] lg:pr-0">
								<p className="text-secondary text-[2rem]  my-[10px] font-medium">
									Tailored for organizations seeking a customized Learning Management System (LMS) solution for staff training,
									onboarding, and more.
								</p>
								<p className="text-gray text-[1.4rem]  my-[10px] font-normal">
									ClassCube Enterprise offers corporate organizations the power of owning their personalized space within our
									learning management system
								</p>
								<button
									className="my-[1.3rem] cursor-pointer py-[10px] px-[20px] rounded-[5px] bg-primary text-[1.4rem] text-white shadow-btnShadow"
									onClick={() => {
										window.open("https://enterprise.classcube.online", "_blank");
									}}
								>
									Get Started
								</button>
							</div>
							<div className="my-[3rem]">
								<img src={ClasscubeEnterprise} alt="ClassCube Basic" width={1000} />
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export const Testimony = () => {
	return (
		<>
			<div className="max-w-[1400px] mx-auto my-[3rem] md:my-[8rem]  p-10 ">
				<h3 className="text-left text-secondary text-[3.6rem] font-semibold  leading-[4.8rem] tracking-[0rem] md:w-[80%] lg:w-[50%]">
					Modern Schools and Businesses already use ClassCube
				</h3>
				<div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-[3rem] my-[4rem]">
					<div className="flex flex-col gap-[0.8rem] bg-lightGray py-[2.2rem] px-[3.5rem] rounded-[2rem]">
						<QuotesIcon />
						<p className="my-[1rem] text-gray text-[1.6rem] font-normal leading-[2.7rem]">
							I've been using ClassCube for my school, and it's been a game-changer. The platform allows us to create engaging courses,
							and our students love the interactive content.
						</p>
						<p className="my-[1rem] text-secondary font-semibold text-[1.5rem]">
							Mr Andrew Peters
							<br />
							{/*  <span className='text-[1.3rem] text-[#5E6C85]'>St Gregory’s College</span> */}
						</p>
					</div>
					<div className="flex flex-col gap-[0.8rem] bg-lightGray py-[2.2rem] px-[3.5rem] rounded-[2rem]">
						<QuotesIcon />
						<p className="my-[1rem] text-gray text-[1.6rem] font-normal leading-[2.7rem]">
							Thanks to ClassCube Enterprise, staff training, and onboarding have been streamlined, and our operations have become more
							efficient than ever
						</p>
						<p className="my-[1rem] text-secondary font-semibold text-[1.5rem]">
							Mr Adeyemi
							<br />
							{/*  <span className='text-[1.3rem] text-[#5E6C85]'>St Gregory’s College</span> */}
						</p>
					</div>
					<div className="flex flex-col gap-[0.8rem] bg-lightGray py-[2.2rem] px-[3.5rem] rounded-[2rem]">
						<QuotesIcon />
						<p className="my-[1rem] text-gray text-[1.6rem] font-normal leading-[2.7rem]">
							The flexibility ClassCube Enterprise gives our organization to tailor the platform to our unique requirements has been a
							significant asset to our operations.
						</p>
						<p className="my-[1rem] text-secondary font-semibold text-[1.5rem]">
							Mrs Jennifer
							<br />
							{/*  <span className='text-[1.3rem] text-[#5E6C85]'>St Gregory’s College</span> */}
						</p>
					</div>
					<div className="flex flex-col gap-[0.8rem] bg-lightGray py-[2.2rem] px-[3.5rem] rounded-[2rem]">
						<QuotesIcon />
						<p className="my-[1rem] text-gray text-[1.6rem] font-normal leading-[2.7rem]">
							The platform has given us the power to create and manage training materials seamlessly, and for this, our team is always
							up-to-date with the latest procedures and practices.
						</p>
						<p className="my-[1rem] text-secondary font-semibold text-[1.5rem]">
							Sonia A.
							<br />
							{/*  <span className='text-[1.3rem] text-[#5E6C85]'>St Gregory’s College</span> */}
						</p>
					</div>
					<div className="flex flex-col gap-[0.8rem] bg-lightGray py-[2.2rem] px-[3.5rem] rounded-[2rem]">
						<QuotesIcon />
						<p className="my-[1rem] text-gray text-[1.6rem] font-normal leading-[2.7rem]">
							As an educator, ClassCube Pro has been a lifesaver. I can easily create and share my courses with students worldwide.
						</p>
						<p className="my-[1rem] text-secondary font-semibold text-[1.5rem]">
							James Roberts.
							<br />
							{/*  <span className='text-[1.3rem] text-[#5E6C85]'>St Gregory’s College</span> */}
						</p>
					</div>
					<div className="flex flex-col gap-[0.8rem] bg-lightGray py-[2.2rem] px-[3.5rem] rounded-[2rem]">
						<QuotesIcon />
						<p className="my-[1rem] text-gray text-[1.6rem] font-normal leading-[2.7rem]">
							The transparent revenue-sharing model is a game-changer for content creators like me. Highly recommended
						</p>
						<p className="my-[1rem] text-secondary font-semibold text-[1.5rem]">
							Mr John.
							<br />
							{/*  <span className='text-[1.3rem] text-[#5E6C85]'>St Gregory’s College</span> */}
						</p>
					</div>
				</div>
			</div>
		</>
	);
};

export const Faqs = () => {
	return (
		<>
			<div className="max-w-[1400px] mx-auto my-[2rem] md:my-[5rem] flex flex-col justify-center items-center p-10 ">
				<div className="mb-[5rem] mx-auto md:w-[70%] lg:w-[50%]">
					<h3 className="text-center text-secondary text-[3.6rem] font-semibold  leading-[4.8rem] tracking-[0rem]">
						You Have Questions? We Have Answers
					</h3>
				</div>

				<div className="my-[3rem] w-full lg:w-10/12">
					<CustomAccordion items={classcubeFaqs} />
				</div>
			</div>
		</>
	);
};

export default Home;
