import { Outlet, RouterProvider, createBrowserRouter } from "react-router-dom";
import './App.css';
import { Home, Footer, TopNav, Contact, Privacy, GetStarted } from './page';


function App() {
  const router = createBrowserRouter([
    {
      path: "/",
      element: <AppLayout />,
      children: [
        {
          path: "",
          element: <Home />,
        },
        {
          path: "/contact",
          element: <Contact />,
        },
        {
          path: "/privacy",
          element: <Privacy />,
        },
        {
          path: "/getstarted",
          element: <GetStarted />,
        },
      ],

    }
  ])
  return <RouterProvider router={router} />
}

const AppLayout = () => {
  return (
    <>
      <TopNav />
      <Outlet />
      <Footer />
    </>
  );
};


export default App;
