import { CardIcon, DocumentIcon, MonitorIcon, VideoIcon } from "./svg";

export const features = [
	{
		icon: <MonitorIcon />,
		title: "Digital Learning",
		content:
			"Our platform enables a seamless transition from traditional learning to an immersive online experience, accessible anytime, anywhere.",
	},
	{
		icon: <VideoIcon />,
		title: "Live Classes",
		content: "Interact with both educators and learners in real time through live classes. Whether as a student, a professional, or a business.",
	},
	{
		icon: <DocumentIcon />,
		title: "Seamless Content Creation",
		content: "ClassCube empowers educators to effortlessly create dynamic lessons with videos, docs, live streams, texts, and more.",
	},
	{
		icon: <CardIcon />,
		title: "Easy & Simplified Payment",
		content: "Our straightforward and transparent payment system ensures a hassle-free experience for learners and creators alike.",
	},
];

export const classcubeFaqs = [
	{
		title: "What is ClassCube, and how does it work?",
		content:
			"ClassCube is a versatile educational platform that combines digital innovation with human connection. It offers various versions to cater to schools, educators, content creators, and organizations.",
	},
	{
		title: "How do I know which ClassCube product to sign up for?",
		content: (
			<ul>
				<li className="my-5">Choose the ClassCube product that best aligns with your need:</li>
				<li className="my-5">
					ClassCube Basic: Ideal for educational institutions looking to digitize and enhance their learning environments.
				</li>
				<li className="my-5">ClassCube Pro: Perfect for educators and content creators who want to offer courses and earn revenue.</li>
				<li className="my-5">
					ClassCube Enterprise: Tailored for organizations seeking a customized LMS solution for staff training, onboarding, and more.
				</li>
			</ul>
		),
	},
	{
		title: "Can I collaborate with others on ClassCube?",
		content:
			"Yes, collaboration is possible on ClassCube Pro. You can collaborate with fellow educators and content creators to create and deliver engaging content.",
	},
	{
		title: "How can I get started with ClassCube?",
		content:
			"Getting started with ClassCube is easy. Simply sign up for the product that suits you best and explore the intuitive dashboard to begin your journey.",
	},
	{
		title: "Is my data safe on ClassCube?",
		content:
			"ClassCube prioritizes data security. We employ robust security measures to protect your information and ensure a safe learning environment.",
	},
	{
		title: "What types of content can I create on ClassCube?",
		content:
			"ClassCube offers versatile content creation options, including text-based lessons, videos, live streams, documents, and more. You can tailor your content to match your teaching or learning style.",
	},
	{
		title: "How does the payment system work on ClassCube?",
		content:
			"ClassCube provides a transparent payment system for those offering courses or content. Revenue-sharing models may vary depending on the type you choose. Review the details of each type to understand the payment structure.",
	},
	{
		title: "How can ClassCube benefit businesses and organizations?",
		content:
			"ClassCube Enterprise is designed to support businesses and organizations by streamlining staff training, onboarding, and standard operation procedures. It's a powerful tool for enhancing efficiency and productivity.",
	},
];

export const testimony = [
	{
		user: "Mr Andrew Peters",
		content: `I've been using ClassCube for my school, and it's been a game-changer.
        The platform allows us to create engaging courses, and our students love the interactive content.`,
	},
	{
		user: "Mr Adeyemi",
		content: `Thanks to ClassCube Enterprise, staff training, and onboarding have been streamlined, and our operations
        have become more efficient than ever`,
	},
	{
		user: "Mrs Jennifer",
		content: `The flexibility ClassCube Enterprise gives our organization to tailor the platform to our unique
        requirements has been a significant asset to our operations.`,
	},
	{
		user: "Sonia A.",
		content: `The platform has given us the power to create and manage training materials seamlessly, and for this,
        our team is always up-to-date with the latest procedures and practices.`,
	},
	{
		user: "James Roberts.",
		content: ` As an educator, ClassCube Pro has been a lifesaver. I can easily create and share my courses with students worldwide.`,
	},
	{
		user: "Mr John.",
		content: `   The transparent revenue-sharing model is a game-changer for content creators like me. Highly recommended`,
	},
];
