import React from 'react'

const PrivacyPolicy = () => {
    return (
        <>
            <div className='max-w-[1400px] mx-auto my-[4rem] p-10'>

                <div className='mb-[5rem] mx-auto md:w-[70%] '>
                    <h1 className="text-center text-secondary text-[3.6rem] font-medium  leading-[4.8rem] tracking-[0.1rem]">Privacy and Policy</h1>
                </div>

                <div className='py-[3rem]'>
                    <h3 className='text-[2.5rem] text-secondary my-[1.8rem] font-semibold leading-[2.8rem]'>
                        1. Introduction
                    </h3>
                    <p className='text-gray text-[1.8rem] leading-[3.3rem]'>
                        Revocube Technologies Multi-Global Limited have created this Privacy Policy to explain how Revocube Technologies Multi-Global Limited collect, use, disclose and protect your Personal Data, including any other information.

                        You accept this Privacy Policy and hereby give Revocube Technologies Multi-Global Limited consent to save, process and use your Personal Data to the extent as allowed by law when you provide us with details of your Personal Data or by clicking on the “accept” button.<br /> You have the right to withdraw your consent at any time, provided that we do not have another legal basis to keep processing your Personal Data.

                        We may change this Privacy Policy from time to time. If we make changes, we will notify you by revising the date at the top of this policy, and in some cases, we may provide you with additional notice (such as by adding a statement to the homepages of our website or [mobile application] or by sending you an email notification).

                        We encourage you to review the Privacy Policy whenever you interact with us to stay informed about our information practices and the ways you can help protect your privacy.

                    </p>
                </div>

                <div className='py-[3rem]'>
                    <h3 className='text-[2.5rem] text-secondary my-[1.8rem] font-semibold leading-[2.8rem]'>
                        2. Collection of Information
                    </h3>
                    <p className='text-gray text-[1.8rem] leading-[3.3rem]'>
                        We may collect and process the following information about you:
                    </p>

                    <ol className="list-inside text-gray text-[1.8rem] leading-[3.3rem] px-10 my-[1rem]">
                        <li className="mb-5 relative pl-6">
                            <p className="absolute left-0 top-0 w-1 h-6 rounded-full flex items-start justify-center">
                                1.
                            </p>
                            Information that you provide to us, for example when you fill out a contact or web form, or if you register to receive alerts or updates.
                        </li>
                        <li className="mb-5 relative pl-6">
                            <p className="absolute left-0 top-0 w-1 h-6 rounded-full flex items-start justify-center">
                                2.
                            </p>
                            When you provide your Personal Data while signing up for a service or purchasing a product.
                        </li>
                        <li className="mb-5 relative pl-6">
                            <p className="absolute left-0 top-0 w-1 h-6 rounded-full flex items-start justify-center">
                                3.
                            </p>
                            Personal Data that we obtain or learn, such as information about the browser or device you use to access this site, how you use this site and the pages you visit, traffic and location data
                        </li>
                        <li className="mb-5 relative pl-6">
                            <p className="absolute left-0 top-0 w-1 h-6 rounded-full flex items-start justify-center">
                                4.
                            </p>
                            When you contact our customer support whether by phone, email, or chat.
                        </li>
                        <li className="mb-5 relative pl-6">
                            <p className="absolute left-0 top-0 w-1 h-6 rounded-full flex items-start justify-center">
                                5.
                            </p>
                            We may also ask you for information if you experience problems when using this site. We may also ask you to complete surveys for research purposes, although you don’t have to respond to these.
                        </li>
                        <li className="mb-5 relative pl-6">
                            <p className="absolute left-0 top-0 w-1 h-6 rounded-full flex items-start justify-center">
                                6.
                            </p>
                            Personal Data we receive from other sources: We are working closely with third parties (including, for example, payment processors). We will notify you when we receive Personal Data about you from them and the purposes for which we intend to use that Personal Data.
                        </li>

                    </ol>
                </div>

                <div className='py-[3rem]'>
                    <h3 className='text-[2.5rem] text-secondary my-[1.8rem] font-semibold leading-[2.8rem]'>
                        3. Use of Personal Data
                    </h3>
                    <p className='text-gray text-[1.8rem] leading-[3.3rem]'>
                        We may collect and process the following information about you:
                    </p>
                    <ol className="list-inside text-gray text-[1.8rem] leading-[3.3rem] px-10 my-[1rem]">
                        <li className="mb-5 relative pl-6">
                            <p className="absolute left-0 top-0 w-1 h-6 rounded-full flex items-start justify-center">
                                1.
                            </p>
                            Provide, maintain, and improve our services.
                        </li>
                        <li className="mb-5 relative pl-6">
                            <p className="absolute left-0 top-0 w-1 h-6 rounded-full flex items-start justify-center">
                                2.
                            </p>
                            Provide and deliver the products and services you request, process transactions and send you related information, including confirmations.
                        </li>
                        <li className="mb-5 relative pl-6">
                            <p className="absolute left-0 top-0 w-1 h-6 rounded-full flex items-start justify-center">
                                3.
                            </p>
                            Verify your identity and prevent fraud.
                        </li>
                        <li className="mb-5 relative pl-6">
                            <p className="absolute left-0 top-0 w-1 h-6 rounded-full flex items-start justify-center">
                                4.
                            </p>
                            Send you technical notices, updates, security alerts and support and administrative messages.
                        </li>
                        <li className="mb-5 relative pl-6">
                            <p className="absolute left-0 top-0 w-1 h-6 rounded-full flex items-start justify-center">
                                5.
                            </p>
                            Respond to your comments, questions and requests and provide customer service.
                        </li>
                        <li className="mb-5 relative pl-6">
                            <p className="absolute left-0 top-0 w-1 h-6 rounded-full flex items-start justify-center">
                                6.
                            </p>
                            Communicate with you about products, services, offers, promotions, rewards, and events offered by Revocube Technologies Multi-Global Limited and others, and provide news and information we think will be of interest to you.
                        </li>
                        <li className="mb-5 relative pl-6">
                            <p className="absolute left-0 top-0 w-1 h-6 rounded-full flex items-start justify-center">
                                7.
                            </p>
                            Monitor and analyse trends, usage and activities in connection with our services.
                        </li>
                        <li className="mb-5 relative pl-6">
                            <p className="absolute left-0 top-0 w-1 h-6 rounded-full flex items-start justify-center">
                                8.
                            </p>
                            Personalize and improve the services and provide advertisements, content or features that match user profiles or interests.
                        </li>
                        <li className="mb-5 relative pl-6">
                            <p className="absolute left-0 top-0 w-1 h-6 rounded-full flex items-start justify-center">
                                9.
                            </p>
                            Process and deliver contest or promotion entries and rewards.
                        </li>
                        <li className="mb-5 relative pl-6">
                            <p className="absolute left-0 top-0 w-1 h-6 rounded-full flex items-start justify-center">
                                10.
                            </p>
                            Link or combine with information we get from others to help understand your needs and provide you with better service.
                        </li>
                        <li className="mb-5 relative pl-6">
                            <p className="absolute left-0 top-0 w-1 h-6 rounded-full flex items-start justify-center">
                                11.
                            </p>
                            Carry out any other purpose for which the Personal Data was collected.
                        </li>
                    </ol>
                    <p className='text-gray text-[1.8rem] leading-[3.3rem]'>Revocube Technologies Multi-Global Limited is based in Nigeria and the information we collect is governed by the Nigeria Data Protection Regulation (NDPR). By accessing or using the Services or otherwise providing information to us, you consent to the processing and transfer of information in and to Nigeria [and other countries].</p>
                </div>

                <div className='py-[3rem]'>
                    <h3 className='text-[2.5rem] text-secondary my-[1.8rem] font-semibold leading-[2.8rem]'>
                        4. Disclosure of Information
                    </h3>
                    <p className='text-gray text-[1.8rem] leading-[3.3rem]'>
                        Revocube Technologies Multi-Global Limited only discloses Personal Data with other companies or individuals in the following limited circumstances:
                    </p>
                    <ol className="list-inside text-gray text-[1.8rem] leading-[3.3rem] px-10 my-[1rem]">
                        <li className="mb-5 relative pl-6">
                            <p className="absolute left-0 top-0 w-1 h-6 rounded-full flex items-start justify-center">
                                1.
                            </p>
                            We may provide such Personal Data to affiliated companies or other trusted businesses or persons for the purpose of processing Personal Data on our behalf. We require that these parties agree to process such Personal Data based on our instructions and in compliance with the Nigeria Data Protection Regulation (NDPR) and any other appropriate confidentiality and security measures. When they no longer need your Personal Data to fulfil this service, they will dispose of the details in line with the NDPR.
                        </li>
                        <li className="mb-5 relative pl-6">
                            <p className="absolute left-0 top-0 w-1 h-6 rounded-full flex items-start justify-center">
                                2.
                            </p>
                            With other third-parties such as email service providers that perform marketing services on Revocube Technologies Multi-Global Limited’s behalf.
                        </li>
                        <li className="mb-5 relative pl-6">
                            <p className="absolute left-0 top-0 w-1 h-6 rounded-full flex items-start justify-center">
                                3.
                            </p>
                            With other non-affiliated companies for our everyday business purposes, such as to process transactions, maintain accounts, respond to court orders and legal investigations.
                        </li>
                        <li className="mb-5 relative pl-6">
                            <p className="absolute left-0 top-0 w-1 h-6 rounded-full flex items-start justify-center">
                                4.
                            </p>
                            In response to a request for Personal Data, if we are required by, or we believe disclosure is in accordance with, any applicable law, regulation or legal process.
                        </li>
                        <li className="mb-5 relative pl-6">
                            <p className="absolute left-0 top-0 w-1 h-6 rounded-full flex items-start justify-center">
                                5.
                            </p>
                            With relevant law enforcement officials or other third parties, such as investigators or auditors, if we believe it is appropriate.
                        </li>
                        <li className="mb-5 relative pl-6">
                            <p className="absolute left-0 top-0 w-1 h-6 rounded-full flex items-start justify-center">
                                6.
                            </p>
                            We may also share aggregated or de-identified or anonymized Information, which cannot reasonably be used to identify you.
                        </li>
                    </ol>
                    <p className='text-gray text-[1.8rem] leading-[3.3rem]'>When Revocube Technologies Multi-Global Limited does not have a lawful basis for disclosure of your Personal Data, Revocube Technologies Multi-Global Limited will obtain consent from you before sharing your Personal Data with third parties. Where Revocube Technologies Multi-Global Limited need to transfer your Personal Data to another country, such country must have an adequate data protection law. We will seek your consent where we need to send your Personal Data to a country without an adequate data protection law.</p>
                </div>

                <div className='py-[3rem]'>
                    <h3 className='text-[2.5rem] text-secondary my-[1.8rem] font-semibold leading-[2.8rem]'>
                        5. Security
                    </h3>
                    <p className='text-gray text-[1.8rem] leading-[3.3rem]'>
                        Revocube Technologies Multi-Global Limited takes reasonable measures to help protect all Personal Data about you from loss, theft, misuse and unauthorized access, disclosure, alteration and destruction. Additionally, Revocube Technologies Multi-Global Limited implements policies designed to protect the confidentiality and security of your Personal Data, including a Data Protection Policy. We have also taken measures to comply with provision of security facilities for the protection of your Personal Data through the set-up of firewalls, limited access to specified authorized individuals, encryption and continuous capacity building for relevant personnel. We therefore have digital and physical security measures to limit and eliminate possibilities of data privacy breach incidents.
                    </p>
                </div>
                <div className='py-[3rem]'>
                    <h3 className='text-[2.5rem] text-secondary my-[1.8rem] font-semibold leading-[2.8rem]'>
                        6.  How Long We Keep Your Personal Data
                    </h3>
                    <p className='text-gray text-[1.8rem] leading-[3.3rem]'>
                        We will hold your Personal Data on Revocube Technologies Multi-Global Limited’s systems for as long as it is necessary to fulfil the purpose for which it was collected or to comply with legal, regulatory or internal policy requirements. </p>
                </div>
                <div className='py-[3rem]'>
                    <h3 className='text-[2.5rem] text-secondary my-[1.8rem] font-semibold leading-[2.8rem]'>
                        7.  Where We Store Your Personal Data
                    </h3>
                    <p className='text-gray text-[1.8rem] leading-[3.3rem]'>
                        The Personal Data Revocube Technologies Multi-Global Limited collects from you may be transferred to and stored at a destination outside Nigeria. By submitting your Personal Data, you agree to this transfer, storing or processing. We will take all steps reasonably necessary to ensure that your Personal Data is treated securely with an appropriate level of protection and that the transfer is lawful.</p>
                </div>

                <div className='py-[3rem]'>
                    <h3 className='text-[2.5rem] text-secondary my-[1.8rem] font-semibold leading-[2.8rem]'>
                        8. Your Rights as a Data Subject
                    </h3>
                    <p className='text-gray text-[1.8rem] leading-[3.3rem]'>
                        The law gives you certain rights in respect to your Personal Data Revocube Technologies Multi-Global Limited hold about you. Below is a highlight of some of those rights. At any point while we are in possession of or processing your Personal Data, you, the Data Subject has the following rights:</p>
                    <ol className="list-inside text-gray text-[1.8rem] leading-[3.3rem] px-10 my-[1rem]">
                        <li className="mb-5 relative pl-6">
                            <p className="absolute left-0 top-0 w-1 h-6 rounded-full flex items-start justify-center">
                                1.
                            </p>
                            RIGHT OF ACCESS - You have the right to request a copy of the information that we hold about you. We will respond to your access request within one-month of receiving your request. Where we are not able to provide this personal data to you within the One-Month timeline provided by law, we will communicate same to you, and may request an additional time within which we will provide the information to you. Your Personal Data shall be provided to you in a structured, commonly used and machine-readable format.
                        </li>
                        <li className="mb-5 relative pl-6">
                            <p className="absolute left-0 top-0 w-1 h-6 rounded-full flex items-start justify-center">
                                2.
                            </p>
                            RIGHT TO RECTIFY – You have the right to correct the Personal Data we hold about you that is inaccurate.
                        </li>
                        <li className="mb-5 relative pl-6">
                            <p className="absolute left-0 top-0 w-1 h-6 rounded-full flex items-start justify-center">
                                3.
                            </p>
                            RIGHT TO BE FORGOTTEN – In certain circumstances you may ask for the data we hold about you to be erased from our record.
                        </li>
                        <li className="mb-5 relative pl-6">
                            <p className="absolute left-0 top-0 w-1 h-6 rounded-full flex items-start justify-center">
                                4.
                            </p>
                            RIGHT TO RESTRICT PROCESSING – Where certain conditions apply, you have a right to restrict processing of your Personal Data.
                        </li>
                        <li className="mb-5 relative pl-6">
                            <p className="absolute left-0 top-0 w-1 h-6 rounded-full flex items-start justify-center">
                                5.
                            </p>
                            RIGHT TO PORTABILITY – You have the right to have your Personal Data transferred to another organisation
                        </li>
                        <li className="mb-5 relative pl-6">
                            <p className="absolute left-0 top-0 w-1 h-6 rounded-full flex items-start justify-center">
                                6.
                            </p>
                            LODGE COMPLAINT – You have a right to lodge a complaint about the handling of your Personal Data with the Nigeria Data Protection Bureau (NDPB) at info@ndpb.gov.ng
                        </li>
                        <li className="mb-5 relative pl-6">
                            <p className="absolute left-0 top-0 w-1 h-6 rounded-full flex items-start justify-center">
                                7.
                            </p>
                            RIGHT TO OBJECT – You have the right to object to the Processing of Personal Data.
                        </li>
                    </ol>
                    <p className='text-gray text-[1.8rem] leading-[3.3rem]'>NDPB’s website <a href='https://ndpb.gov.ng' className='hover:text-secondary'> (https://ndpb.gov.ng)</a> has a wealth of useful information in respect of your rights over your Personal Data. If you wish to exercise your rights, you may contact our Data Protection Officer at dataofficer@revocube.com or you may write to us at 40b Oduduwa Way, GRA Ikeja, Lagos.</p>
                </div>





                <div className='py-[3rem]'>
                    <h3 className='text-[2.5rem] text-secondary my-[1.8rem] font-semibold leading-[2.8rem]'>
                        9.   Breach/ Privacy Violation
                    </h3>
                    <p className='text-gray text-[1.8rem] leading-[3.3rem]'>    In the event of a breach of security leading to the accidental or unlawful destruction, loss, alteration, unauthorized disclosure of, or access to Personal Data, Revocube Technologies Multi-Global Limited shall within 72 (Seventy-Two) hours of having knowledge of such breach report the details of the breach to NDPB.

                        Furthermore, where we ascertain that such breach is detrimental to your rights and freedoms in relation to your Personal Data, we shall within 7 (Seven) days of having knowledge of the occurrence of such breach take steps to inform you of the breach incident, the risk to your rights and freedoms resulting from such breach and any course of action to remedy said breach.</p>
                </div>
                <div className='py-[3rem]'>
                    <h3 className='text-[2.5rem] text-secondary my-[1.8rem] font-semibold leading-[2.8rem]'>
                        10.   Questions or Concerns
                    </h3>
                    <p className='text-gray text-[1.8rem] leading-[3.3rem]'> If you have any questions or concerns about this Privacy Policy or would like to contact us for any reason, you can contact us at info@revocube.com </p> </div>

            </div>
        </>
    )
}

export default PrivacyPolicy